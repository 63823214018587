import React from "react"
import styled from "styled-components"
import Accordian from "../Accordian/Accordian"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const AccordianImplementation = (itemsObject) => {
  const items = itemsObject.items
  return (
    <Accordian>
      {items.map(({ title, content, Component, className }, i) => (
        <Accordian.Item key={i} title={title} bright>
          {Component && (
            <Content className={`component-content ${className}`}>
              <Component />
            </Content>
          )}
          {content && (
            <Content
              className="html-content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </Accordian.Item>
      ))}
    </Accordian>
  )
}

import redCheck from "../../images/icons/red-check.svg"

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Content = styled.div`
  &.html-content {
    padding: 20px;
  }

  background: white;

  h2.capitalize {
    text-transform: capitalize;
  }

  > * {
    margin: 20px 0;
  }

  ul:not(.blocks-gallery-grid) li,
  ol:not(.blocks-gallery-grid) li {
    display: flex;
    > * {
      display: contents;
    }
  }

  ul:not(.blocks-gallery-grid) li:before,
  ol:not(.blocks-gallery-grid) li:before {
    content: url(${redCheck});
    color: red;
    display: block;
    margin-right: 15px;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }

  figcaption {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    padding: 5px 10px;
    border-radius: 4px;
    color: #828282;
    background: #e0e0e0;
  }

  blockquote p {
    font-weight: 400;
    font-size: 18px;
    border-left: 8px solid #0988bb;
    padding: 7px;
    padding-left: 15px;
    color: #7d828c;
  }
  a {
    color: red;
  }
`
export default AccordianImplementation
