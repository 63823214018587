/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Helmet from "react-helmet"
import styled from "styled-components"
import Heading from "../zzz/organisms/heading"
import Inner from "../zzz/layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import { LinkWrapper as Link } from "../utils/linkWrapper"
import { getImage } from "../lib/util"
import AccordianImplementation from "../components/FAQ/AccordianImplementation"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
// A single Faq page
const FaqPage = ({ pageContext, location, data }) => {
  const post_id = data?.faq?.pageId ?? ""
  const seo = data?.faq?.seo ?? ""
  const title = data?.faq?.title ?? "FAQ"
  const subTitle =
    data?.faq?.heading?.subTitle ?? "All your questions answered."
  const faqCategories = data?.faq?.faq?.faqCategories ?? ""
  const { slug, allFaqPages, allFaqSlugs } = pageContext
  const featuredImg = getImage(data?.faq)
  const allFaqPagesTitles = data?.allLocalWpGraphQlPages?.nodes ?? ""

  const allFaqChildPages = allFaqPagesTitles.filter(
    (faqSlug) => faqSlug.slug != "faq"
  )

  const jellyFishSEO = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  }
  let faqNavCustomOrder = 0
  let tempOrder = 0
  let qaaCustomMobileOrder = 0

  return (
    <Layout location={location}>
      <SEO title={title} location={location} post_id={post_id} seo={seo} />
      <Heading
        location={location}
        image={featuredImg}
        subTitle={subTitle}
        titleOverload={title}
      />
      <GreyBackroundStyling>
        <Inner>
          <CustomFlexBoxStyling>
            {allFaqChildPages.map((faq, index) => {
              const faqPageVal = allFaqPages.filter(
                (faqSlug) => faqSlug.slug == faq.slug
              )
              const faqPath = faqPageVal[faqPageVal.length - 1].path

              faqNavCustomOrder = tempOrder + faqNavCustomOrder + 1

              if (slug == faq.slug) {
                tempOrder = 1
                qaaCustomMobileOrder = faqNavCustomOrder + tempOrder
              } else {
                tempOrder = 0
              }
              return (
                <StyledLinkAsFlexItem
                  key={index}
                  to={faqPath}
                  faqNavCustomOrder={faqNavCustomOrder}
                >
                  <FaqNamesStyling
                    className={slug == faq.slug ? "current-page" : "other"}
                  >
                    <h2 dangerouslySetInnerHTML={{ __html: faq.title }} />
                  </FaqNamesStyling>
                </StyledLinkAsFlexItem>
              )
            })}
            {!faqCategories ? null : (
              <QaaFlexItemStyling qaaCustomMobileOrder={qaaCustomMobileOrder}>
                {faqCategories.map((faqCategory, index) => {
                  return (
                    <div key={index}>
                      {!faqCategory.faqCategoryHeading ? null : (
                        <Styledh3>{faqCategory.faqCategoryHeading}</Styledh3>
                      )}
                      {!faqCategory.faqQuestionAndAnswersRepeater ? null : (
                        <RaisedSectionStyling>
                          <AccordianImplementation
                            items={faqCategory.faqQuestionAndAnswersRepeater.map(
                              (qaa, QIndex) => {
                                jellyFishSEO.mainEntity.push({
                                  "@type": "Question",
                                  name: `${qaa.faqQuestions}`,
                                  acceptedAnswer: {
                                    "@type": "Answer",
                                    text: `${qaa.faqAnswers
                                      .replace(/<[^>]+>/g, " ")
                                      .replace(/\s{2,}/g, " ")
                                      .replace(/&amp;/g, "&")
                                      .trim()}`,
                                  },
                                })
                                return {
                                  title: qaa.faqQuestions,
                                  content: qaa.faqAnswers,
                                }
                              }
                            )}
                          />
                        </RaisedSectionStyling>
                      )}
                    </div>
                  )
                })}
                <Helmet key={title}>
                  <script type="application/ld+json" className="Faq-Schema">
                    {JSON.stringify(jellyFishSEO)}
                  </script>
                </Helmet>
              </QaaFlexItemStyling>
            )}
          </CustomFlexBoxStyling>
        </Inner>
      </GreyBackroundStyling>
    </Layout>
  )
}

export default FaqPage

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Styledh3 = styled.h3`
  text-align: center;
  font-weight: 400;
  width: fit-content;
  margin: 60px auto 20px auto;
  &:after {
    display: block;
    content: "";
    border-bottom: 2px solid #fd3237;
    width: 80%;
    margin: 0 auto;
  }
`

const RaisedSectionStyling = styled.div`
  box-shadow: 0 2px 2px 1px #5656562e;
  border-radius: 3px;
  overflow: hidden;
`

const CustomFlexBoxStyling = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const QaaFlexItemStyling = styled.div`
  width: 100%;
  order: 1;

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    order: ${({ qaaCustomMobileOrder }) => qaaCustomMobileOrder};
    margin: 0 0 50px;
  }
`

const StyledLinkAsFlexItem = styled(Link)`
  @media (min-width: ${({ theme }) => theme.breakLarge}) {
    & + & {
      margin-left: 20px;
    }
    width: calc(20% - 16px);
    order: 1;
  }

  width: 100%;
  order: ${({ faqNavCustomOrder }) => faqNavCustomOrder};
  margin: 0 0 20px;
`

const FaqNamesStyling = styled.div`
  background: #223247;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  padding: 20px;
  border-radius: 4px;
  height: 120px;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease-in-out;
  }
  & h2 {
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
  }
  &.current-page h2 {
    color: #fd3237;
  }

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    height: 120px;
    margin-bottom: 0px;
  }
`

const GreyBackroundStyling = styled.div`
  background: #f6f6f6;
  padding: 60px 0;
`

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================
export const FAQ_QUERY = graphql`
  query($allFaqSlugs: [String], $slug: String!) {
    allLocalWpGraphQlPages(
      filter: { slug: { in: $allFaqSlugs } }
      sort: { fields: pageId, order: ASC }
    ) {
      nodes {
        title
        slug
      }
    }
    faq: localWpGraphQlPages(slug: { eq: $slug }) {
      title
      id
      pageId
      heading {
        subTitle
      }
      faq {
        faqCategories {
          faqCategoryHeading
          faqQuestionAndAnswersRepeater {
            faqAnswers
            faqQuestions
          }
        }
      }
      seo {
        woocommerceSchema
        yoastDescription
        yoastMeta
        yoastSchema
        yoastTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
